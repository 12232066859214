import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./notice.styles.scss";
import { updateFilterNotice } from "../../redux/municipality-info/municipality-info.actions";
import { selectFilterNoticeByWard } from "../../redux/municipality-info/municipality-info.selectors";
import NoticesComponent from "../../components/notice/Notices.component";

const NoticePage = ({ isMyNotice, updateFilterNotice }) => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="section-head">Notices</h1>
        <div className="d-flex">
          {window.localStorage.getItem("isLoggedIn") === "true" && (
            <Button
              className="myNotice"
              size="sm"
              onClick={() => updateFilterNotice()}
            >
              {isMyNotice ? "Hide" : "Show"} My Ward Notice
            </Button>
          )}
          <Link to={"/notices"} className="view-all">
            View All
          </Link>
        </div>
      </div>
      <div className="notice-block">
        <div className="card">
          <NoticesComponent />
        </div>
      </div>
      <div />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  isMyNotice: selectFilterNoticeByWard,
});

const mapDispatchToProps = (dispatch) => ({
  updateFilterNotice: (municipalityInfo) =>
    dispatch(updateFilterNotice(municipalityInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoticePage);
