import React from "react";
import { connect } from "react-redux";
import { Button, Image, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import ProfileImg from "../../assets/images/profile.png";
import ProfileCanvasComponent from "../profile/ProfileCanvas.component";
import agent from "../../agent";
import { createStructuredSelector } from "reselect";
import { setUserProfile } from "../../redux/profile/profile.action";
import { selectProfile } from "../../redux/profile/profile.secector";

class HeaderSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileShow: false,
      menuBarShow: false,
    };
  }

  async componentDidMount() {
    const { userProfile } = this.props;
    if (window.localStorage.getItem("isLoggedIn") === "true") {
      userProfile(await agent.publicDigitalData.getUserProfile());
    }
  }

  toggleProfileShow = () => {
    this.setState({ profileShow: !this.state.profileShow });
  };

  // Top menu bar
  toggleMenuShow = () => {
    this.setState({ menuBarShow: !this.state.menuBarShow });
  };
  render() {
    const { profile } = this.props;
    return (
      <>
        <header className="header-sticky d-flex align-items-center justify-content-between">
          <div className="header-logo d-flex align-items-center">
            <Link to={"/"} className="">
              <Image src={Logo} />
            </Link>
            <h1>
              Chandragiri Digital
              <br />
              Profile
            </h1>
          </div>
          <div className="top-nav d-flex align-items-center">
            <Navbar className={this.state.menuBarShow ? "mobile-show" : ""}>
              <Nav>
                <a
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                  href={process.env.REACT_APP_SYSTEM_DIGITAL_PROFILE_URL}
                >
                  Digital Profile
                </a>
                <NavLink className="nav-link" target="_blank" to="/gis-map">
                  GIS Digital Profile
                </NavLink>
                <NavLink className="nav-link" to={"/customer-service"}>
                  Customer Services
                </NavLink>
              </Nav>
            </Navbar>
            {profile && (
              <>
                <div className="profile" onClick={this.toggleProfileShow}>
                  {/* Image size: 32 x 32 */}
                  <Image src={profile.image ? profile.image : ProfileImg} />
                </div>
              </>
            )}
            <Button className="menu-button" onClick={this.toggleMenuShow}>
              <i className="fa-solid fa-bars"></i>
            </Button>
          </div>
        </header>
        {profile && (
          <>
            <ProfileCanvasComponent
              handleProfileClose={this.toggleProfileShow}
              profileShow={this.state.profileShow}
              profile={profile}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  profile: selectProfile,
});

const mapDispatchToProps = (dispatch) => ({
  userProfile: (profile) => dispatch(setUserProfile(profile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSection);
