import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import GisDigitalImg from '../../assets/images/icons/gis-profile.svg';
import GisMapImg from '../../assets/images/icons/gis-map.svg';
import InstituteImg from '../../assets/images/icons/institutional-info.svg';
import ProfileImg from '../../assets/images/icons/d-profile.svg';
import CustomerImg from '../../assets/images/icons/customer-service.svg';
import { Link } from 'react-router-dom';
import './home-navigation.styles.scss'

function HomeNavigation() {
  return (
    <div className="gis-links">
      <Row>
        <Col>
          <Card className="mb-4">
            <a className='link-cover' rel="noreferrer" href={process.env.REACT_APP_SYSTEM_BASE_URL} target="_blank">{ }</a>
            <Card.Img variant="top" src={ProfileImg} />
            <Card.Title>Digital Profile Admin</Card.Title>
          </Card>
        </Col>
        <Col>
          <Card>
            <a className='link-cover' rel="noreferrer" href={process.env.REACT_APP_GIS_ANALYSIS_BASE_URL} target="_blank">{ }</a>
            <Card.Img variant="top" src={GisDigitalImg} />
            <Card.Title>GIS Analysis System</Card.Title>
          </Card>
        </Col>
        <Col>
          <Card className="mb-4">
            <a className='link-cover' rel="noreferrer" href={process.env.REACT_APP_SYSTEM_DIGITAL_PROFILE_URL} target="_blank">{ }</a>
            <Card.Img variant="top" src={InstituteImg} />
            <Card.Title>Digital Profile</Card.Title>
          </Card>
        </Col>
        <Col>
          <Card className="mb-4">
            <Link className='link-cover' rel="noreferrer" target="_blank" to={"/gis-map"}></Link>
            <Card.Img variant="top" src={GisMapImg} />
            <Card.Title>GIS Digital Profile</Card.Title>
          </Card>
        </Col>

        <Col>
          <Card>
            <Link to={'/customer-service'} className='link-cover'></Link>
            <Card.Img variant="top" src={CustomerImg} />
            <Card.Title>Customer Services</Card.Title>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default HomeNavigation