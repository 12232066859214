import React, { useState } from "react";
import HeaderComponent from "../../components/header/Header.component";
import { Button } from "react-bootstrap";
import NoticeListComponent from "../../components/notice/NoticeList.component";
import "./notice.styles.scss";
import { Breadcrumb, Container } from "react-bootstrap";

function NoticeListPage() {
  const [myWard, setmyWard] = useState(false);
  return (
    <div>
      <HeaderComponent />
      <Container>
        <div className="top-section">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Notices</Breadcrumb.Item>
          </Breadcrumb>
          {window.localStorage.getItem("isLoggedIn") === "true" && (
            <Button
              className="myNotice"
              size="sm"
              onClick={() => setmyWard(!myWard)}
            >
              {myWard ? "Hide" : "Show"} My Ward Notice
            </Button>
          )}
        </div>
        <div className="notice-block sub-main bordered mb-5">
          <div className="sub-main_title mb-4">
            <h2>Notices</h2>
          </div>
          <NoticeListComponent myWard={myWard} />
        </div>
      </Container>
    </div>
  );
}
export default NoticeListPage;
