import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { setDatasetCatrgories, updateDatasetChecked } from '../../redux/dataset/dataset.actions';
import { selectDatasetCategories } from '../../redux/dataset/dataset.selectors';
import { Accordion, Button, Form, Image } from 'react-bootstrap';

import agent from '../../agent';

function style(style) {
  return {
    background: style ? style.fill ? style.fillColor : "none" : "green",
    border: style ? `${style.color} solid` : "green"
  }
}

class GisSidemenuComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSidebar: false
    }
  }

  async componentDidMount() {
    const { setDatasetCatrgories } = this.props
    setDatasetCatrgories(await agent.gisDigitalData.getDatasetCatrgories());
  }


  openSidebar = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  }

  render() {
    const { datasetCategories, updateDatasetChecked } = this.props
    return (
      <div className={`sidemenu gis-menu ${this.state.showSidebar ? 'collapse-show' : ''}`}>
        <Button className='openNav' onClick={this.openSidebar}>
          <i className={`fa-solid fa-caret-${this.state.showSidebar ? "left" : "right"}`}></i>
        </Button>
        <div className="gis-sidenav">
          <Accordion defaultActiveKey="1" alwaysOpen>
            {datasetCategories.map(categories => (
              <Accordion.Item key={`d-cat-${categories.id}`} eventKey={categories.id}>
                <Accordion.Header>{categories.name}</Accordion.Header>
                <Accordion.Body>
                  <div className="land-type sidebar-checkbox">
                    {categories.datasets.map(dataset => (
                      <Form.Group key={`dataset-${categories.id}-${dataset.tbl_name}`} className="d-flex mb-4" controlId={dataset.tbl_name}>
                        <Form.Check className='checkboxes me-3' checked={dataset.is_checked} onChange={() => updateDatasetChecked(dataset)} />
                        <Form.Label>{(dataset.geom_type === "ST_Point") ? (<>
                          {
                            (dataset.style.shape === "icon") ?
                              (<Image src={dataset.style.style.icon} />) :
                              (<span className='type-color water' style={style(dataset.style.style)}></span>)
                          }
                        </>)
                          : (<span className='type-color water' style={style(dataset.style.style)}></span>)}
                          {dataset.name}</Form.Label>
                      </Form.Group>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}

          </Accordion>
          {/* <div className="theme-radio">
            <Form.Check
              type="radio"
              id="default-radio"
              label="Road data"
              className='road-radio'
              checked
            />
          </div> */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  datasetCategories: selectDatasetCategories
})

const mapDispatchToProps = dispatch => ({
  setDatasetCatrgories: dataset => dispatch(setDatasetCatrgories(dataset)),
  updateDatasetChecked: dataset => dispatch(updateDatasetChecked(dataset))
})


export default connect(mapStateToProps, mapDispatchToProps)(GisSidemenuComponent)