import React from 'react'
import {
  Chart as ChartJS,
  registerables
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectBuildingUsed } from "../../redux/municipality-info/municipality-info.selectors";

ChartJS.register(...registerables);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        boxWidth: 15,
        padding: 30,
      }
    },
    title: {
      display: true,
      text: '',
    },

  }
};

export const color = [
  '#67B7DC',
  '#DC6788',
  '#DC67CE',
  '#A367DC',
  '#C767DC',
  '#8067DC',
  '#A367DC',
  '#6794DC',
]


const UseOfBuilding = ({ buildingUsed }) => {
  const labels = buildingUsed.labels;
  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: buildingUsed.data,
        borderColor: color,
        backgroundColor: color,
        cutout: '60%',
      }
    ],
  };
  return (
    <>
      <div className='card mb-4'>
        <div className="card-title">
          Use Of Building
        </div>
        <div className="pie-chart-section building">
          <Doughnut options={options} data={data} />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  buildingUsed: selectBuildingUsed
});

export default connect(mapStateToProps) (UseOfBuilding)