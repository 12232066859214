import React from 'react'
import {
  Chart as ChartJS,
  registerables
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectBuildingOwnership } from "../../redux/municipality-info/municipality-info.selectors";

ChartJS.register(...registerables);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 15,
        padding: 30,
      }
    },
    title: {
      display: true,
      text: '',
    },
  }
};

export const color = [
  '#67B7DC',
  '#DC6788',
  '#DC67CE',
  '#A367DC',
  '#C767DC',
  '#8067DC',
  '#A367DC',
  '#6794DC',
]

const BuildingOwnership = ({ buildingOwnership }) => {
  const labels = buildingOwnership.labels;
  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: buildingOwnership.data,
        borderColor: color,
        backgroundColor: color,
      }
    ],
  };
  return (
    <>
      <div className='card mb-4'>
        <div className="card-title">
          Building Ownership
        </div>
        <div className="pie-chart-section ownership">
          <Doughnut options={options} data={data} />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  buildingOwnership: selectBuildingOwnership
});

export default connect(mapStateToProps)(BuildingOwnership)