import React from 'react';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Table } from 'react-bootstrap';
import uuid from "react-uuid";
import { selectMunicipalityWardData } from "../../redux/municipality-info/municipality-info.selectors";

const AllDataTable = ({ municipalityWardData }) => {
  return (
    <>
      <div className='card mb-4'>
        <div className="card-title">
          All Data
        </div>
        <div className="munici-detail">
          <div className="theme-table-wrap">
            <Table responsive className='theme-table table-striped'>
              <thead>
                <tr>
                  <th>Ward</th>
                  <th>No. of Houses</th>
                  <th>Male</th>
                  <th>Female</th>
                  <th>Others</th>
                  <th>Population</th>
                </tr>
              </thead>
              <tbody>
                {municipalityWardData.map((wardData) => {
                  return (<tr key={uuid()}>
                    <td>{wardData.ward}</td>
                    <td>{wardData.house}</td>
                    <td>{wardData.male}</td>
                    <td>{wardData.female}</td>
                    <td>{wardData.other}</td>
                    <td>{wardData.total}</td>
                  </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  municipalityWardData: selectMunicipalityWardData
});

export default connect(mapStateToProps)(AllDataTable)