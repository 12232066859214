import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { selectMunicipalityStatistics } from "../../redux/municipality-info/municipality-info.selectors";
import  uuid from "react-uuid";

const MunicipalityStatistics = ({ municipalityStats }) => {
  return (
    <div className='card mb-4'>
      <div className="card-title">
        Municipality Statistics
      </div>
      <Row>
        {(municipalityStats.map((stat) => {
          return (
            <Col key={uuid()} lg={6} xl={4}>
            <div className="card statis mb-4">
              <div className='statis-head d-flex justify-content-between'>
                  <h5>{stat.title }</h5>
                  <h5>{stat.count}/{stat.total }</h5>
              </div>
              <div className="statis-bar">
                  <ProgressBar className={`statis-bar_${stat.slug}`} now={stat.percentage_en} />
                <div className="statis-bar_body d-flex justify-content-between">
                    <p>{stat.category }</p>
                    <p>{stat.percentage }%</p>
                </div>
              </div>
            </div>
          </Col>
          );
        })
        )
        }

      </Row>
    </div>
  )
}
const mapStateToProps = createStructuredSelector({
  municipalityStats: selectMunicipalityStatistics
});

export default connect(mapStateToProps)(MunicipalityStatistics)