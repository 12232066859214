import React from "react";
import Header from "../../components/header/Header.component";
import { Container } from "react-bootstrap";
import "./notice.styles.scss";
import NoticeDetailComponent from "../../components/notice/NoticeDetail.component";

function NoticeDetailPage() {
  return (
    <div>
      <Header />
      <Container>
        <NoticeDetailComponent />
      </Container>
    </div>
  );
}

export default NoticeDetailPage;
