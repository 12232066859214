import React from 'react';
import {
  Chart as ChartJS,
  registerables
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectBuildingByWard } from "../../redux/municipality-info/municipality-info.selectors";

ChartJS.register(...registerables);
// const legendMargin = {
//   id: 'legendMargin',
//   beforeInit(chart, legend, options) {
//     const fitValue = chart.legend.fit;
//     chart.legend.fit = function fit() {
//       fitValue.bind(chart.legend)();
//       return this.height += 30;
//     }
//   }
// }

// export const plugins = [legendMargin];

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      align: 'start',
      labels: {
        padding: 30,
      }
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },

};

const BuildingDataByWard = ({ buildingByWard }) => {
  const labels = buildingByWard.labels;
  const data = {
    labels,
    datasets: [
      {
        label: 'Building',
        data: buildingByWard.data,
        borderColor: '#5E84C3',
        backgroundColor: '#5E84C3',
      }
    ],
  };
  return (
    <>
      <div className='card mb-4'>
        <div className="card-title">
          Building Data By Ward
        </div>
        <div className="bar-chart-section">
          <Bar options={options} data={data} />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  buildingByWard: selectBuildingByWard
});

export default connect(mapStateToProps)(BuildingDataByWard)