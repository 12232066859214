import React from 'react'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Col, Row } from 'react-bootstrap';
import uuid from 'react-uuid';
import { selectLocalProfile } from '../../redux/municipality-info/municipality-info.selectors';
const LocalProfile = ({ localProfile }) => {
  return (
    <div className='card mb-4'>
      <div className="card-title">
        Local Profile
      </div>
      <div className="local-block">
        <Row>
          {localProfile.map((profile) => {
            return (<Col key={uuid()}>
              <div className="local-block_item">
                <h1>{profile.count}</h1>
                <p>{profile.label}</p>
              </div>
            </Col>
            )
          })}
        </Row>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  localProfile: selectLocalProfile
});

export default connect(mapStateToProps)(LocalProfile)