import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../components/header/Header.component";
import Banner from "../../components/banner/banner.component";
import SurveyReport from "../../components/survey-report/SurveyReport.component";
import HomeNavigation from "../../components/navigation-section/HomeNavigation.component";
import NoticePage from "../notice/Notices.pages";
import "./home.styles.scss";

const HomePage = () => {
  return (
    <>
      <Header />
      <Banner />
      <section>
        <Container>
          <HomeNavigation />
        </Container>
      </section>

      <section>
        <Container>
          <SurveyReport />
        </Container>
      </section>
      <section>
        <Container>
          <NoticePage />
        </Container>
      </section>
    </>
  );
};

export default HomePage;
