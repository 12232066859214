import React from "react";
import { Form } from "react-bootstrap";

const HouseApiToggle = ({ setChecked, checked }) => {
  const toggleChecked = () => {
    setChecked(!checked);
  };
  return (
    <div className="layer-checkbox-house">
      <Form.Check
        inline
        label="Enable Building and Road Data"
        name="group1"
        type="checkbox"
        checked={checked}
        onChange={toggleChecked}
        id="inline2"
      />
    </div>
  );
};

export default HouseApiToggle;
