import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectDatasetCategories } from "../../redux/dataset/dataset.selectors";
import GeoLayer from "./GeoLayer.component";

const DatasetLayer = ({
  datasetCategories,
  southWest,
  northEast,
  zoomLevel,
  setLoading,
  checked,
  setDisplayDetails,
  setPopupDetails,
  setPopupDataset,
}) => {
  return (
    <>
      {datasetCategories.map((category) => {
        return (
          <div key={`dataset-cat-${category.id}`}>
            {category.datasets.map((dataset) => {
              return (
                <div key={`dataset-plot-${dataset.tbl_name}`}>
                  {dataset.is_checked && (
                    <GeoLayer
                      dataset={dataset}
                      southWest={southWest}
                      northEast={northEast}
                      zoomLevel={zoomLevel}
                      setLoading={setLoading}
                      checked={checked}
                      setDisplayDetails={setDisplayDetails}
                      setPopupDetails={setPopupDetails}
                      setPopupDataset={setPopupDataset}
                    />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  datasetCategories: selectDatasetCategories,
});

export default connect(mapStateToProps)(DatasetLayer);
