import React from 'react';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ListGroup } from 'react-bootstrap';
import { selectDataByWard } from '../../redux/municipality-info/municipality-info.selectors';

const MunicipalityDetails = ({ dataByWard }) => {
  return (
    <>
      <div className='card mb-4'>
        <div className="card-title">
          Municipality Population Details
        </div>
        <div className="munici-detail">
          {(dataByWard !== undefined) &&
            (<ListGroup>
              <ListGroup.Item className='d-flex'>
                <p><i className="fa-solid fa-house-chimney"></i> Total House Number</p>
                <p>{dataByWard.house}</p>
              </ListGroup.Item>
              <ListGroup.Item className='d-flex'>
                <p><i className="fa-solid fa-people-group"></i> Total Population</p>
                <p>{dataByWard.total}</p>
              </ListGroup.Item>
              <ListGroup.Item className='d-flex'>
                <p><i className="fa-solid fa-person"></i> Male</p>
                <p>{dataByWard.male}</p>
              </ListGroup.Item>
              <ListGroup.Item className='d-flex'>
                <p><i className="fa-solid fa-female"></i> Female</p>
                <p>{dataByWard.female}</p>
              </ListGroup.Item>
              <ListGroup.Item className='d-flex'>
                <p><i className="fa-solid fa-genderless"></i> Others</p>
                <p>{dataByWard.other}</p>
              </ListGroup.Item>
            </ListGroup>)
          }
        </div>
      </div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  dataByWard: selectDataByWard
});

export default connect(mapStateToProps)(MunicipalityDetails)