import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  MapContainer,
  ScaleControl,
  ZoomControl,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet"; // Import leaflet
import { selectAboutApp } from "../../redux/about-app/about-app.selectors";
import DatasetLayer from "./DatasetLayer.component";
import ImageTileLayer from "./ImageTileLayer.comonent";
import ImageLayerToggle from "./ImageLayerToggle.component";
import HouseApiToggle from "./HouseApiToggle.component";
import SearchLayer from "./SearchLayer.component";
import TopSearch from "./TopSearch.component";
import RoutingForm from "./RoutingForm.component";
import RightDetailPopup from "./RightDetailPopup.component";
import RoutingLayer from "./RoutingLayer.component";
import "./gis-map-section.styles.scss";
import { number } from "yup/lib/locale";

function MapEventHandler({ setSouthWest, setNorthEast, setZoomLevel }) {
  useMapEvents({
    load: () => {},
    moveend: (e) => {
      const bounds = e.target.getBounds();
      const southWest = `${bounds.getWest()},${bounds.getSouth()}`;
      const northEast = `${bounds.getEast()},${bounds.getNorth()}`;
      setSouthWest(southWest);
      setNorthEast(northEast);
    },
    zoomend: (e) => {
      setZoomLevel(e.target.getZoom());
    },
  });
  return null;
}

function ChangeMapView({ coords, zoom }) {
  const map = useMap();
  useEffect(() => {
    map.setView(coords, zoom);
  }, [coords, zoom, map]);
  return null;
}

function GisMapSection({ aboutApp }) {
  // Calculate initial bounds based on the initial center and zoom level
  const initialBounds = L.latLng(aboutApp.center).toBounds(
    aboutApp.zoom * 1000
  );
  const initialSouthWest = `${initialBounds.getWest()},${initialBounds.getSouth()}`;
  const initialNorthEast = `${initialBounds.getEast()},${initialBounds.getNorth()}`;

  const [southWest, setSouthWest] = useState(initialSouthWest);
  const [northEast, setNorthEast] = useState(initialNorthEast);
  const [zoomLevel, setZoomLevel] = useState(15);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const [displayDetails, setDisplayDetails] = useState(false);
  const [popupDetails, setPopupDetails] = useState(null);
  const [popupDataset, setPopupDataset] = useState(null);

  return (
    <div className="map__section" id="gis-map">
      {loading && (
        <div className="map-loader">
          <div className="spinner-border"></div>
        </div>
      )}
      <MapContainer
        className="leaflet_map"
        center={aboutApp.center}
        zoom={aboutApp.zoom}
        scrollWheelZoom={true}
        zoomControl={false}
      >
        <ZoomControl position="bottomright" />
        <ChangeMapView coords={aboutApp.center} zoom={aboutApp.zoom} />
        <ScaleControl position="bottomleft" />
        <TileLayer
          attribution={aboutApp.attr}
          url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
        />
        <ImageTileLayer />
        <DatasetLayer
          southWest={southWest}
          northEast={northEast}
          zoomLevel={zoomLevel}
          setLoading={setLoading}
          checked={checked}
          setDisplayDetails={setDisplayDetails}
          setPopupDetails={setPopupDetails}
          setPopupDataset={setPopupDataset}
        />
        <MapEventHandler
          setSouthWest={setSouthWest}
          setNorthEast={setNorthEast}
          setZoomLevel={setZoomLevel}
        />
        <SearchLayer
          setDisplayDetails={setDisplayDetails}
          setPopupDetails={setPopupDetails}
        />
        <RoutingLayer />
      </MapContainer>
      <ImageLayerToggle />
      <HouseApiToggle setChecked={setChecked} checked={checked} />
      <TopSearch setDisplayDetails={setDisplayDetails} />
      <RoutingForm />
      {displayDetails && (
        <RightDetailPopup
          popupDetails={popupDetails}
          popupDataset={popupDataset}
          setDisplayDetails={setDisplayDetails}
        />
      )}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  aboutApp: selectAboutApp,
});

export default connect(mapStateToProps)(GisMapSection);
