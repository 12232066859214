import React, { useState, useEffect } from "react";
import { useParams } from 'react-router';
import { Breadcrumb, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import agent from '../../agent'

function NoticeDetailComponent() {
  const { slug } = useParams()
  const [post, setPost] = useState({});
  useEffect(() => {
    const fetchPost = async () => {
      await agent.publicDigitalData.getNoticeBySlug(slug).then(res => {
        setPost(res)
      }).catch(err => { })
    };
    fetchPost();
  },[]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/notices" }}>Notices</Breadcrumb.Item>
        <Breadcrumb.Item active>{post.title} </Breadcrumb.Item>
      </Breadcrumb>
      {(post) && (
        <div className="notice-detail sub-main bordered">
          <div className="notice-detail_title">
            <h5 className="notice-detail_title">
              {post.title}
            </h5>
            <span className="date"><i className="fa-regular fa-clock"></i> {post.publish_date}</span>
          </div>
          {(post.image !== null) && (
            <div className='notice-detail_img'>
              <Image height='auto' src={post.image} />
            </div>
          )}
          <p className="notice-detail_description">
            {post.details}
          </p>
        </div>
      )}
    </>
  )
}

export default NoticeDetailComponent