import React, { useState } from "react";
import Helper from "../../utils/helper";
import { Image } from "react-bootstrap";
import { Button } from "react-bootstrap";

const RightDetailPopup = ({
  popupDetails,
  popupDataset,
  setDisplayDetails,
}) => {
  const [hide, setHide] = useState(false);
  const closePopup = () => {
    setDisplayDetails(false);
  };
  return (
    <>
      {popupDetails !== null && popupDetails !== undefined ? (
        <div className="popup-detail-right">
          <div className="popup-header">
            Details{" "}
            <span className="toggle" onClick={() => setHide(!hide)}>
              <i
                className={`fa fa-angle-${hide ? "down" : "up"}`}
                aria-hidden="true"
              ></i>
            </span>
          </div>
          <div className={`popup-right-body ${hide ? "hide" : ""}`}>
            <div className="popup-detail-right-child">
              {popupDetails?.image && popupDetails.image !== undefined && (
                <Image
                  src={popupDetails.image}
                  className="img-fluid"
                  style={{ maxHeight: "250px", width: "100%" }}
                ></Image>
              )}
              {Object.keys(popupDetails)
                .sort((a, b) => (a === "name" ? -1 : b === "name" ? 1 : 0))
                .map(
                  (key) =>
                    key !== "image" &&
                    popupDetails[key] !== "" &&
                    popupDetails[key] !== null && (
                      <p
                        key={
                          popupDataset
                            ? `${popupDataset.tbl_name}-${key}`
                            : `${popupDetails[key]}-${key}`
                        }
                      >
                        <span>{Helper.titleFormatter(key)} </span>:
                        <span style={{ marginLeft: "8px", flexGrow: 1 }}>
                          {popupDetails[key]}
                        </span>
                      </p>
                    )
                )}
            </div>
          </div>
          <hr className="my-2" />
          <div className="text-end">
            <Button variant="primary" size="sm" onClick={closePopup}>
              Close
            </Button>
          </div>
        </div>
      ) : (
        <div className="popup-detail-right">
          <div className="popup-header">
            Details{" "}
            <span className="toggle" onClick={() => setHide(!hide)}>
              <i
                className={`fa fa-angle-${hide ? "down" : "up"}`}
                aria-hidden="true"
              ></i>
            </span>
          </div>
          <div className={`popup-right-body ${hide ? "hide" : ""}`}>
            <div className="popup-detail-right-child">No data</div>
          </div>
          <hr className="my-2" />
          <div className="text-end">
            <Button variant="primary" size="sm" onClick={closePopup}>
              Close
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default RightDetailPopup;
