import React from 'react'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Col, Dropdown, ButtonGroup, Row } from 'react-bootstrap';
import uuid from 'react-uuid';
import BuildingDataByWard from './BuildingDataByWard.component'
import AllDataTable from './AllDataTable.component'
import BuildingOwnership from './BuildingOwnership.component'
import MunicipalityDetails from './MunicipalityDetails.component'
import MunicipalityStatistics from './MunicipalityStatistics.component'
import UseOfBuilding from './UseOfBuilding.component'
import LocalProfile from './LocalProfile.component';
import agent from '../../agent';
import {
  selectWardfilterList,
  selectWardSelected
} from "../../redux/municipality-info/municipality-info.selectors";
import {
  updateFilterWard,
  setChartData
} from '../../redux/municipality-info/municipality-info.actions';
import './survey-report.styles.scss';

class SurveyReport extends React.Component {

  async filterChecked(filter) {
    const { updateFilterWard, setChartData} = this.props;
    setChartData(await agent.publicDigitalData.getMunicipalityChart(isNaN(filter.value) ? "": filter.value))
    updateFilterWard(filter)
  }


  render() {
    const { wardfilterList, wardSelected } = this.props;
    return (
      <>
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <h1 className='section-head mb-0'>Survey Report</h1>
          <div className="filter d-flex">
            <div className="filter-select">
              <Dropdown as={ButtonGroup} className='select_block me-3'>
                <Dropdown.Toggle id="dropdown-basic">
                  {wardSelected.key}
                  <i className="fa-solid fa-angle-down"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {wardfilterList.map((filter) => {
                    return (<Dropdown.Item key={uuid()} onClick={()=> this.filterChecked(filter)}>{filter.key}</Dropdown.Item>)
                  })
                }

                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <Button className='search-btn'><i className="fa-solid fa-magnifying-glass"></i></Button> */}
          </div>
        </div>

        <Row>
          <Col lg={12} xl={12}>
            <MunicipalityStatistics />
          </Col>
          <Col lg={12} xl={12}>
            <LocalProfile />
          </Col>
          <Col lg={12} xl={5}>
            <MunicipalityDetails />
            <BuildingOwnership />
          </Col>
          <Col lg={12} xl={7}>
            <AllDataTable />
          </Col>
          <Col lg={12} xl={5}>
            <UseOfBuilding />
          </Col>
          <Col lg={12} xl={7}>
            <BuildingDataByWard />
          </Col>
        </Row>
      </>
    )
  }
}
const mapStateToProps = createStructuredSelector({
  wardfilterList: selectWardfilterList,
  wardSelected: selectWardSelected
})

const mapDispatchToProps = dispatch => ({
  updateFilterWard: municipalityInfo => dispatch(updateFilterWard(municipalityInfo)),
  setChartData: municipalityInfo => dispatch(setChartData(municipalityInfo))
})

export default connect(mapStateToProps, mapDispatchToProps)(SurveyReport)