import React, { useState } from "react";
import { connect } from "react-redux";
import { setDatasetSources } from "../../redux/dataset/dataset.actions";
import { selectDatasetSourcesByDataset } from "../../redux/dataset/dataset.selectors";
import { GeoJSON, Popup, Tooltip } from "react-leaflet";
import { Image } from "react-bootstrap";
import agent from "../../agent";
import { pointerIcon } from "../../utils/util";
import Helper from "../../utils/helper";
import L from "leaflet";

class GeoLayer extends React.Component {
  async fetchDatasetSourcesBuilding() {
    const {
      dataset,
      setDatasetSources,
      southWest,
      northEast,
      zoomLevel,
      setLoading,
    } = this.props;
    let apiUrl = dataset.tbl_name;
    if (
      (dataset.tbl_name === "pn_npl_building_house_number" ||
        dataset.tbl_name === "pn_npl_road_network_edit") &&
      southWest &&
      northEast &&
      zoomLevel >= 17
    ) {
      setLoading(true);
      apiUrl += `?window=${southWest}:${northEast}`;
      const sources = await agent.gisDigitalData.getDatasetSources(apiUrl);
      setDatasetSources(sources);
      setLoading(false);
    }
  }

  async fetchDatasetSources() {
    const {
      dataset,
      setDatasetSources,
      southWest,
      northEast,
      zoomLevel,
      setLoading,
      checked,
    } = this.props;
    let apiUrl = dataset.tbl_name;
    if (
      (dataset.tbl_name === "pn_npl_building_house_number" ||
        dataset.tbl_name === "pn_npl_road_network_edit") &&
      southWest &&
      northEast &&
      zoomLevel >= 17 &&
      checked
    ) {
      setLoading(true);
      apiUrl += `?window=${southWest}:${northEast}`;
      const sources = await agent.gisDigitalData.getDatasetSources(apiUrl);
      setDatasetSources(sources);
      setLoading(false);
    } else if (
      dataset.tbl_name !== "pn_npl_building_house_number" &&
      dataset.tbl_name !== "pn_npl_road_network_edit"
    ) {
      setLoading(true);
      const sources = await agent.gisDigitalData.getDatasetSources(
        dataset.tbl_name
      );
      setDatasetSources(sources);
      setLoading(false);
    }
  }

  async componentDidMount() {
    const { datasetSources } = this.props;
    if (datasetSources === undefined) {
      await this.fetchDatasetSources();
    }
  }

  async componentDidUpdate(prevProps) {
    const { southWest, northEast, zoomLevel, checked } = this.props;
    if (
      southWest !== prevProps.southWest ||
      northEast !== prevProps.northEast
    ) {
      if (zoomLevel >= 17 && checked) {
        await this.fetchDatasetSourcesBuilding();
      }
    }
  }

  onEachFeature = (feature, layer) => {
    const {
      dataset,
      datasetSources,
      setDisplayDetails,
      setPopupDetails,
      setPopupDataset,
    } = this.props;
    if (dataset.geom_type === "ST_Point") {
      if (dataset.style.shape !== "icon") layer.setStyle(dataset.style.style);
    } else {
      layer.setStyle(dataset.style.style);
    }

    layer.on({
      click: (e) => {
        e.originalEvent.stopPropagation(); // Stop propagation to prevent map click event
        datasetSources.geojson.map((i) => {
          if (e.target.feature.geometry.coordinates === i.geojson.coordinates) {
            setPopupDetails(i.properties);
          }
        });
        setPopupDataset(dataset);
        setDisplayDetails(true);
      },
    });
  };

  pointToLayer = (feature, latlng) => {
    const { dataset } = this.props;
    if (latlng) {
      if (dataset.style.shape === "icon")
        return L.marker(latlng, {
          icon: pointerIcon(dataset.style.style.icon),
        });
      else
        return L.circleMarker(latlng, {
          radius: 7,
        });
    }
  };

  render() {
    const { datasetSources, dataset } = this.props;

    return (
      <>
        {datasetSources !== undefined && (
          <>
            {datasetSources.geojson.map((geodata) => (
              <GeoJSON
                key={`${dataset.tbl_name}-${geodata.id}`}
                data={geodata.geojson}
                onEachFeature={this.onEachFeature}
                pointToLayer={this.pointToLayer}
              >
                {/* {geodata.properties && (
                  <Popup className="map-popup">
                    <div className="popup-detail">
                      {geodata.properties.image &&
                        geodata.properties.image !== undefined && (
                          <Image src={geodata.properties.image}></Image>
                        )}
                      {Object.keys(geodata.properties).map(
                        (key) =>
                          key !== "image" &&
                          geodata.properties[key] !== "" &&
                          geodata.properties[key] !== null && (
                            <p key={`${dataset.tbl_name}-${geodata.id}-${key}`}>
                              <span>{Helper.titleFormatter(key)} : </span>
                              {geodata.properties[key]}
                            </p>
                          )
                      )}
                    </div>
                  </Popup>
                )} */}
                {dataset.show_label && (
                  <Tooltip
                    className="map-tooltip"
                    direction="center"
                    offset={[0, -10]}
                    opacity={1}
                    permanent
                  >
                    {geodata.properties.name}
                  </Tooltip>
                )}
              </GeoJSON>
            ))}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  datasetSources: selectDatasetSourcesByDataset(props.dataset.tbl_name)(state),
});

const mapDispatchToProps = (dispatch) => ({
  setDatasetSources: (dataset) => dispatch(setDatasetSources(dataset)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeoLayer);
