export const CustomerServiceActionTypes = {
  SET_DASHBOARD_DATA: "SET_DASHBOARD_DATA",
  SET_HOUSEHOLD_INFORMATION: "SET_HOUSEHOLD_INFORMATION",
  SET_INSTITUTION_INFORMATION: "SET_INSTITUTION_INFORMATION",
  SET_INSTITUTION_CATEGORY_LIST: "SET_INSTITUTION_CATEGORY_LIST",
  SET_UPDATED_HOUSEHOLD_INFORMATION: "SET_UPDATED_HOUSEHOLD_INFORMATION",
  SET_COMPLAIN: "SET_COMPLAIN",
  ADD_COMPLAIN: "ADD_COMPLAIN",
  SET_SERVICE: "SET_SERVICE",
  ADD_SERVICE: "ADD_SERVICE",
  SET_EDIT_CONFIG: "SET_EDIT_CONFIG",
  TOGGLE_SHOW_IAMGE_LAYER: "TOGGLE_SHOW_IAMGE_LAYER",
  TOGGLE_UPDATE_SUCCESS: "TOGGLE_UPDATE_SUCCESS",
  UPDATE_VIEWED_STATUS: "UPDATE_VIEWED_STATUS"
}