import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Image, Pagination } from "react-bootstrap";
import { selectNotice } from "../../redux/municipality-info/municipality-info.selectors";
import agent from "../../agent";
import Loader from "../loader/loader.component";
import { Link } from "react-router-dom";
import { setMunicipalityNotice } from "../../redux/municipality-info/municipality-info.actions";

function NoticeListComponent({ notice, myWard }) {
  useEffect(() => {
    fetchData(1, myWard);
  }, [myWard]);
  const dispatch = useDispatch();
  const fetchData = async (page = 1, ward = false) => {
    dispatch(
      setMunicipalityNotice({
        notice: await agent.publicDigitalData.getNotice(page, ward),
      })
    );
  };
  const gotoPage = (page) => {
    if (page > 0 && page !== notice.page && page <= notice.total_page) {
      fetchData(page, myWard);
    }
  };
  return (
    <>
      <div className="card notice-list">
        {notice !== null ? (
          <>
            {notice.notice.map((notices, index) => (
              <div
                key={index}
                className="card-block d-flex justify-content-between"
              >
                <div className="card-block_body d-flex">
                  <div className="card-image">
                    <Image src={notices.thumbnail} />
                  </div>
                  <div>
                    <Link to={`/notices/detail/${notices.slug}`}>
                      <h5> {notices.title}</h5>
                    </Link>
                    <div className="details">{notices.details}</div>
                  </div>
                </div>
                <div className="card-block_time">
                  <p>
                    <i className="fa-regular fa-clock"></i>{" "}
                    {notices.publish_date}
                  </p>
                </div>
              </div>
            ))}
            <div className="pagination-wrap">
              <p>
                Showing {notice.notice.length} out of {notice.total_count}{" "}
                entries
              </p>
              <Pagination>
                <Pagination.Prev
                  active={notice.is_first}
                  onClick={() => gotoPage(notice.page - 1)}
                >
                  Previous
                </Pagination.Prev>
                {Array.from(Array(notice.total_page), (e, i) => {
                  return (
                    <Pagination.Item
                      key={i}
                      active={i + 1 === notice.page}
                      onClick={() => gotoPage(i + 1)}
                    >
                      {i + 1}
                    </Pagination.Item>
                  );
                })}
                <Pagination.Next
                  active={notice.is_last}
                  onClick={() => gotoPage(notice.page + 1)}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  notice: selectNotice,
});

export default connect(mapStateToProps)(NoticeListComponent);
