import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectBanner } from "../../redux/municipality-info/municipality-info.selectors";
import { Carousel, Image } from "react-bootstrap";
import "./banner.styles.scss";

function Banner({ banner }) {
  return (
    <div className="banner">
      <Carousel fade indicators={false} controls={banner.length > 1}>
        {banner.map((ban, index) => (
          <Carousel.Item key={index}>
            <Image src={ban.image} />
            <div className="banner-text">
              <h2>{ban.title}</h2>
              {/* <p>Chandragiri Survey detail form</p> */}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}
const mapStateToProps = createStructuredSelector({
  banner: selectBanner,
});

export default connect(mapStateToProps)(Banner);
